@use "colors";
@use "layout";

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Base components
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/* @ Input component
/* @ Select component
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-form-field.mat-form-field-appearance-fill {
    .mat-mdc-text-field-wrapper {
        @apply border-brandDark-300 rounded-[6px] #{'!important'};

        & .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
            min-height: 2.5rem;  // 44px
            height: 100%;
        }
    }

    &.fuse-mat-emphasized-affix {
        .mat-mdc-text-field-wrapper {
            & .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix {
                @apply bg-brandGrey-200 #{'!important'};
            ;
            }
        }
    }

    &.light-border {
        .mat-mdc-text-field-wrapper {
            @apply border-brandDark-200 rounded-[6px] #{'!important'};
        }
    }

    &.hide-subscript {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}

.mat-mdc-input-element {

    &::placeholder {
        transition: none !important;
        @apply text-brandDark-300 #{'!important'};
    }

    &::-moz-placeholder {
        transition: none !important;
        @apply text-brandDark-300 #{'!important'};
    }

    &::-webkit-input-placeholder {
        transition: none !important;
        @apply text-brandDark-300 #{'!important'};
    }

    &:-ms-input-placeholder {
        transition: none !important;
        @apply text-brandDark-300 #{'!important'};
    }
}

.mat-mdc-option {
    @apply min-h-10 #{!important};
}

input {
    @apply text-sm md:text-md #{!important};

    &::placeholder {
        transition: none !important;
        @apply text-brandDark-300 #{'!important'};
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Button component
/* ----------------------------------------------------------------------------------------------------- */
.stroke-button {
    @apply flex flex-row whitespace-nowrap w-fit justify-center items-center;
    @apply gap-1.5 text-sm border border-brandGrey-600 rounded-md py-1.5 px-4 cursor-pointer;
}

.flat-button {
    @apply flex flex-row whitespace-nowrap w-fit justify-center items-center;
    @apply gap-1.5 text-sm border border-primary-500 bg-primary-500 text-white rounded-md py-1.5 px-4 cursor-pointer;

  .mat-icon {
    svg path {
      fill: colors.$white;
    }
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Error component
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-form-field-error {
    font-weight: 300;
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Drawer component
/* ----------------------------------------------------------------------------------------------------- */

.mat-drawer-container {
    @apply text-brandDark-500 bg-white #{'!important'};
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Divider component
/* ----------------------------------------------------------------------------------------------------- */

.divider-class {
    @apply h-px w-full bg-brandDark-50;
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Loader component
/* ----------------------------------------------------------------------------------------------------- */
.loader {
    width: 18px;
    height: 18px;
    border: 4px solid lightgrey;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Details component
/* ----------------------------------------------------------------------------------------------------- */

.details-component-container {
    display: flex;
    height: 100%;
    overflow: hidden;
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Icon component
/* ----------------------------------------------------------------------------------------------------- */

.icon-bold-primary-500 {
    svg path {
        fill: var(--fuse-primary-500) !important;
    }
}

.icon-bold-primary-800 {
  svg path {
    fill: var(--fuse-primary-800) !important;
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Menu Panel component
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-menu-panel {
    .mat-mdc-menu-content {
        padding: 4px 0 !important;
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Mat Menu component
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-menu-item {
    min-height: 40px !important;
}

.group-mat-menu {
    .group-icon {
        &__active {
            svg path {
                @apply fill-primary-500;
            }
        }
    }

    .group-element {
        &__active {
            @apply text-primary-500;
        }
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Mat Paginator component
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-paginator {
    @apply bg-transparent text-sm #{!important};

    .mat-mdc-paginator-container {
        min-height: 40px;
        @apply py-0 #{!important};
    }

    .mat-mdc-form-field-infix {
        @apply py-2 min-h-10 #{!important};
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Buttons group component
/* ----------------------------------------------------------------------------------------------------- */

.display-group {
    display: flex;
    border: 2px solid;
    border-radius: 0.375rem;
    overflow: hidden;

    button {
        @apply border-r border-r-brandDark-50;
    }

    button:first-child {
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
    }

    button:last-child {
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border-right: none;
    }

    .hover-effect {
        transition: background-color 0.3s ease;
    }

    .hover-effect:hover {
        @apply bg-brandDark-50;
    }

    .group-icon {
        &__active {
            svg path {
                @apply fill-primary-500;
            }
        }
    }

    .group-element {
        &__active {
            @apply text-primary-500;
        }
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Snackbar component
/* ----------------------------------------------------------------------------------------------------- */

.success-snackbar {
    .mdc-snackbar__surface {
        background: #15803D !important;
        font-weight: 500 !important;

        .mdc-snackbar__label {
            color: colors.$white !important;
        }

        .mdc-button__label {
            color: colors.$white !important;
        }
    }
}

.error-snackbar {
    .mdc-snackbar__surface {
        background: #DC2626 !important;
        font-weight: 500 !important;

        .mdc-snackbar__label {
            color: colors.$white !important;
        }

        .mdc-button__label {
            color: colors.$white !important;
        }
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Apex Chart component
/* ----------------------------------------------------------------------------------------------------- */

