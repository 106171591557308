// PLATFORM COLORS

// Main
$black: #0d0d0d;
$grey-1: #202020;
//$grey-1: #020617;
$grey-2: #282828;
$grey-3: #333333;
$grey-4: #616161;
$grey-5: #9b9b9b;
$grey-6: #f4f4f4;
$grey-7: #f8f8f8;
$neutral-grey-200: #e5e5e5;
$white: #ffffff;

$light-grey: #d0d0ce;
$dark-grey-50: #4b5150;
$dark-grey-100: #3f4443;
$dark-grey-400: #333636;
$dark-grey-500: #262a29;
$dark-grey-700: #1a1c1c;

// Accent 1
//$blue: #007dba;
$blue: #183650;
$blue-dark: #172551;

$navy: #183650;
$light-blue: #5bc2e7;
$medium-blue: #00a3e0;
$web-blue: #007dba;
$label-blue: #3660F6;

// Accent 2
$pink: #f1b8cb;
$cyan: #85f0f8;
$azure: #00e3ff;
$green: #6bb773;
$red: #b56b69;
$orange: #ff6900;
$light-orange: #ff9e1b;
$light-yellow: #FFE000;

// Other colors
$dark-red: #662833;
$dark-green: #335a30;
$brown: #884c3d;
$dark-yellow: #bec26d;
$lime: #00d819;
$intense-yellow: #ffbd00;
$dark-teal: #2E9094;
$label-green: #4BA330;
$label-red: #EA3323;

// Snackbar
$success-snackbar: #15803d;
$error-snackbar: #dc2626;

// Dark Theme
//$dark-theme-background: #183650;
$dark-theme-background: #262a29;
$dark-card-background: #3f4443;
$dark-app-accent-light-color: #5bc2e7;
$dark-app-accent-medium-color: #007dba;
//$dark-app-accent-medium-color: #00a3e0;
$dark-app-accent-web-color: #007dba;
$text-light-grey: #e5e5e5;

