/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@use "global/colors";
@use "global/layout";
@use "global/fonts" as *;
@use "global/base-components";
@import '@ctrl/ngx-emoji-mart/picker';


* {
  font-family: 'Open Sans', sans-serif;
}

body.dark {
  background-color: colors.$dark-grey-700 !important;
}

html {
  background-color: colors.$grey-6;
}

g {
  &__bg {
    &--light {
      background-color: #fbfbfc !important;
    }
  }

  &__c {
    &--primary {
      color: theme("colors.primary");
    }
  }
}

.freeprivacypolicy-com---nb-simple {
  max-width: 600px !important;
  background-color: rgb(245, 245, 245) !important;
  box-shadow: rgba(149, 157, 165, 0.1) 0 8px 24px;

  .cc-nb-main-container {
    padding: 1rem 3rem !important;
  }

  .cc-nb-title {
    font-size: 18px !important;
    margin-bottom: 0.5rem !important;
    color: rgb(105, 105, 105) !important;
  }

  .cc-nb-text {
    margin-bottom: 1.5rem !important;
    font-size: 14px !important;
    color: rgb(105, 105, 105) !important;
  }

  .cc-nb-buttons-container button {
    border-radius: 20px !important;
    font-weight: normal;

    &:first-child {
      background-color: #1e293bd0 !important;
      color: #ffffff;
    }

    &:nth-child(2) {
      background-color: #1e293bd0 !important;
      color: #ffffff;
    }
  }
}

.cc-pc-head-lang {
  select {
    padding: 5px !important;

    &:focus {
      box-shadow: 0 0 0 2px #1e293bd0 !important;
    }
  }
}

.cc-cp-foot {
  .cc-cp-foot-byline {
    color: #f2f2f2 !important;
    user-select: none;

    a {
      display: none !important;
    }
  }

  button {
    background-color: #1e293bd0 !important;
    color: #ffffff;
    font-weight: normal;
    border-radius: 20px !important;
  }
}

#tidio-chat-iframe {
  inset: auto auto 5px 9px !important;
  transform: translateY(0px) scale(0.9) !important;
}

.dark {
  .mat-dialog-container {
    background: #1f2937 !important;
    color: #cbd5e1 !important;
  }

  .mat-datepicker-content {
    background-color: colors.$dark-grey-700 !important;
    color: #cbd5e1 !important;
  }

  .pac-container {
    background-color: colors.$dark-grey-700;

    .pac-item {
      border-color: colors.$dark-grey-700;
      color: #cbd5e1 !important;
    }

    .pac-item:hover {
      background-color: #475569;
    }

    .pac-item-selected,
    .pac-item-selected:hover {
      background-color: colors.$dark-grey-500;
    }

    .pac-item-query {
      color: #cbd5e1 !important;
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #60a5fa !important;
  }

  .err {
    color: #f87171 !important;
  }

  .mat-form-field-invalid .mat-input-element {
    caret-color: #f87171 !important;
  }
}

@media only screen and (max-width: 600px) {
  g__dialog {
    &--form {
      height: 100vh;
      width: 100vw;
    }
  }
}

@media (max-width: theme("screens.sm")) {
  .mat-datepicker-dialog {
    max-width: 100vw !important;
  }

  .cdk-overlay-pane {
    max-width: 90vw !important;
  }
}
