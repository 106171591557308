@use "colors";

/* BREAKPOINTS */
$breakpoint-xxs: 380px;
$breakpoint-xs: 459px;
$breakpoint-sm: 599px;
$breakpoint-md: 959px;
$breakpoint-lg: 1279px;
$breakpoint-xl: 1439px;
$breakpoint-xxl: 1599px;
$breakpoint-xxxl: 1799px;

.fuse-vertical-navigation-tooltip {
  background-color: colors.$grey-5 !important;
  padding: 6px 12px !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  margin-left: 25px !important;
}

.icon-size-1 {
  width: 4px;
  height: 4px;
  min-width: 4px;
  min-height: 4px;
  font-size: 4px;
  line-height:4px;
}

.icon-size-7-5 {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  font-size: 30px;
  line-height: 30px;
}

.icon-size-9 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  font-size: 36px;
  line-height: 36px;
}

.success-snackbar {
  .mdc-snackbar__surface {
    background: colors.$success-snackbar !important;
    font-weight: 500 !important;

    .mdc-snackbar__label {
      color: colors.$white !important;
    }

    .mdc-button__label {
      color: colors.$white !important;
    }
  }
}

.error-snackbar {
  .mdc-snackbar__surface {
    background: colors.$error-snackbar !important;
    font-weight: 500 !important;

    .mdc-snackbar__label {
      color: colors.$white !important;
    }

    .mdc-button__label {
      color: colors.$white !important;
    }
  }
}

.title-class {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 28px;
  font-weight: 500;
  line-height: 124%; /* 66.96px */
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

/* PANEL */

.panel__menu
  + *
  .cdk-overlay-pane
  > div.mat-mdc-menu-panel
  > div.mat-mdc-menu-content {
  padding: 2px 0 !important;

  .hover-state:hover {
    scale: 1.2;
  }

  .reaction-row {
    .mat-mdc-menu-item-text {
      gap: 0.5rem;
    }
  }

  .active-state {
    &__like {
      scale: 1.1;
      border-bottom: 2px solid #bae6fd;
    }

    &__love {
      scale: 1.1;
      border-bottom: 2px solid #fecaca;
    }

    &__celebrate {
      scale: 1.1;
      border-bottom: 2px solid #15803d;
    }

    &__smile {
      scale: 1.1;
      border-bottom: 2px solid #a5f3fc;
    }

    &__idea {
      scale: 1.1;
      border-bottom: 2px solid #fde047;
    }

    &__curious {
      scale: 1.1;
      border-bottom: 2px solid #ddd6fe;
    }
  }

  .icon-trash,
  .user-square-icon,
  .icon-message,
  .icon-pin,
  .icon-history{
    svg path {
      fill: colors.$grey-4;
    }
  }

  .icon-pencil {
    svg path {
      fill: colors.$grey-4;
    }
  }

  .icon-copy {
    svg path {
      fill: colors.$grey-4;
    }
  }
}

.panel__menu__reactions + * .cdk-overlay-pane > div.mat-mdc-menu-panel {
  min-width: 290px !important;
  max-width: 400px !important;
  width: 100%;
  max-height: 28rem;

  & .mat-mdc-menu-item-text {
    display: flex !important;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    .icon-whatsapp-offices {
      svg path {
        stroke: rgba(var(--fuse-mat-icon-rgb), var(--tw-text-opacity));
      }
    }
  }
}

/* DATEPICKER */

.mat-datepicker-dialog,
.mat-datepicker-popup {
  .mat-datepicker-content {
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--mat-datepicker-calendar-container-background-color);
    color: var(--mat-datepicker-calendar-container-text-color);
  }

  .time-container {
    display: flex;
    justify-content: center;
    color: var(--mat-datepicker-calendar-container-text-color);

    .mat-mdc-form-field.mat-form-field-appearance-fill
      .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex {
      padding: 0 2px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-calendar-body-cell-content {
    color: var(--mat-datepicker-calendar-date-text-color);
    border-color: var(--mat-datepicker-calendar-date-outline-color);
  }

  .mat-calendar-body-selected {
    background-color: var(
      --mat-datepicker-calendar-date-selected-state-background-color
    );
    color: var(--mat-datepicker-calendar-date-selected-state-text-color);
  }

  .mat-calendar-body-today {
    border-color: 1px solid
      var(--mat-datepicker-calendar-date-selected-state-background-color) !important;

    //background-color: var(
    //    --mat-datepicker-calendar-date-selected-state-background-color
    //);
    //color: var(--mat-datepicker-calendar-date-selected-state-text-color);
  }

  .mat-calendar-body-cell-content:not(.mat-calendar-body-today) {
    border: none !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: var(
      --mat-datepicker-calendar-date-hover-state-background-color
    );
  }

  .mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    color: var(--mat-datepicker-calendar-date-disabled-state-text-color);
  }

  .mat-calendar-arrow {
    fill: var(--mat-datepicker-calendar-period-button-icon-color);
  }

  .mat-datepicker-content-touch .mat-datepicker-content-container {
    max-width: 400px !important;
  }
}

/* TOOLTIP */

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--fuse-text-default) !important;
  color: white;

  .dark & {
    background-color: colors.$light-grey !important;
    color: colors.$black !important;
  }
}

.mat-mdc-tooltip-trigger {
  touch-action: auto !important;
}

/* SMALL BUTTON */

.small-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 0.375rem; /* 6px */
  font-size: 12px;
}

/* PARAGRAPH */

.whitespace-break-spaces {
  white-space: break-spaces;
  word-break: break-word;
}

/* OVERFLOW */

.area-scrollable-horizontal {
  overflow: auto hidden;
}

.area-scrollable-vertical {
  overflow: auto hidden;
}

.display-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

/* MENTIONS */

.global-flx-selectable-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 6px;
  width: auto;
  max-height: calc(6 * 36px);
  gap: 4px;
  overflow: auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  .global-flx-selectable-list-item {
    padding: 0.75rem;
    height: 36px;
    min-height: 36px;
    min-width: 8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;

    &:hover {
      font-weight: 600;
      background-color: rgba(238, 238, 238, 0.5);
    }

    > span {
      color: rgba(0, 0, 0, 0.69);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .material-icons {
      color: #51dec2;
    }
  }

  .tag-element {
    &:last-of-type {
      border-bottom: none !important;
    }
  }
}

.global-flx-selectable-list-loader {
  padding: 12px 0;
}

.ngx-text-highlight-tag {
  font-weight: 300;
}


/* THEMES */

.light {
  .text-navbar {
    color: colors.$grey-1;
  }

  .title-class {
    color: colors.$black;
  }

  .mat-drawer-container {
    background-color: colors.$white;
  }
}

.dark {
  .title-class {
    color: colors.$white;
  }

  fuse-card {
    background-color: colors.$dark-grey-100;
  }

  .mat-icon {
    color: colors.$white;
  }

  .text-color {
    color: colors.$text-light-grey !important;
  }

  .mat-mdc-menu-panel,
  .mat-mdc-select-panel,
  .mat-mdc-autocomplete-panel {
    background-color: colors.$dark-grey-700 !important;

    .mat-mdc-option:not(:hover) {
      background-color: transparent !important;
    }

    .mat-mdc-option:hover {
      background-color: colors.$dark-grey-500 !important;
    }
  }

  .panel__menu + * .cdk-overlay-pane > div.mat-mdc-menu-panel {
    background-color: colors.$dark-grey-500 !important;

    & > div.mat-mdc-menu-content {
      .icon-trash {
        svg path {
          stroke: colors.$grey-5;
        }
      }

      .user-square-icon {
        svg path {
          stroke: colors.$grey-5;
        }
      }

      .icon-pencil {
        svg path {
          fill: colors.$grey-5;
        }
      }

      .icon-message,
      .icon-copy,
      .icon-pin,
      .icon-history{
        svg path {
          fill: colors.$grey-5;
        }
      }
    }
  }

  .panel__menu__reactions + * .cdk-overlay-pane > div.mat-mdc-menu-panel {
    background-color: colors.$dark-grey-500 !important;
  }

  .text-navbar {
    color: colors.$grey-6;
  }

  .mat-mdc-form-field.mat-form-field-appearance-fill {
    &.mat-focused:not(.mat-form-field-invalid) {
      .mat-mdc-text-field-wrapper {
        --tw-border-opacity: 0.6 !important;
      }
    }
    .mat-mdc-text-field-wrapper {
      --tw-border-opacity: 0.4 !important;
      border-color: rgb(208 208 206 / var(--tw-border-opacity)) !important;
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
    color: colors.$light-grey !important;

    &::placeholder {
      color: colors.$grey-4 !important;
    }
  }

  .mat-mdc-text-field-wrapper {
    & ::placeholder {
      color: colors.$grey-4 !important;
    }
  }

  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid
    .mdc-notched-outline__leading,
  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid
    .mdc-notched-outline__notch,
  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid
    .mdc-notched-outline__trailing {
    border-color: #f87171 !important;
  }

  .mdc-text-field--outlined.mdc-text-field--invalid:not(
      .mdc-text-field--disabled
    )
    .mdc-floating-label,
  .mdc-text-field--outlined.mdc-text-field--invalid:not(
      .mdc-text-field--disabled
    )
    .mdc-floating-label--float-above {
    color: #f87171 !important;
  }

  .mat-mdc-form-field-error {
    color: #f87171 !important;
  }

  .mat-mdc-select-placeholder {
    color: colors.$grey-4 !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: colors.$dark-grey-100 !important;
  }

  .mat-datepicker-dialog {
    .mat-datepicker-content {
      background-color: colors.$dark-grey-100 !important;
    }
  }

  .mat-drawer-inner-container {
    background-color: colors.$dark-grey-500;
  }

  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.6);
  }

  .mat-mdc-table {
    background-color: colors.$dark-grey-500;
  }

  .mat-drawer-container {
    background-color: colors.$dark-theme-background;
  }

  .global-flx-selectable-list {
    background-color: colors.$dark-grey-500;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .global-flx-selectable-list-item {
      &:hover {
        background-color: rgba(63, 68, 67, 0.5);
      }

      > span {
        color: colors.$grey-6;
      }
    }
  }

  .ngx-text-highlight-element {
    opacity: 0.4;

    &.ngx-text-highlight-tag-hovered {
      opacity: 0.5;
    }
  }

  .table-template {
    &.mat-mdc-table {
      tbody {
        .mat-mdc-row:hover {
          background-color: colors.$dark-grey-100 !important;
        }
      }
    }
  }

  .table {
    &__scroll {
      &-horizontal {
        background-color: colors.$dark-grey-500;
      }
    }
  }
}

/* TABLE */

.table {
  &__scroll {
    &-horizontal {
      overflow-x: auto;
      width: 100%;
      -webkit-overflow-scrolling: touch;
    }

    &-vertical {
      overflow-y: auto;
      height: 100%;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.table-template {
  &.mat-mdc-table {
    width: 100%;
    border-collapse: separate;

    &.resizing {
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: col-resize;
    }

    tbody {
      .mat-mdc-row:hover {
        background: whitesmoke !important;
      }
    }

    .mat-mdc-cell {
      font-weight: 400;
      font-size: 12px;
      padding-top: 3px;
      padding-bottom: 3px;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.mat-mdc-table .mat-mdc-header-cell {
    border-bottom: 2px solid var(--fuse-primary-500);
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
  }

  .mdc-data-table__header-cell:first-of-type,
  .mdc-data-table__cell:first-of-type{
    padding-left: 8px !important;
  }

  .mdc-data-table__header-cell:last-of-type,
  .mdc-data-table__cell:last-of-type{
    padding-right: 8px !important;
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Page layout
/* ----------------------------------------------------------------------------------------------------- */

.page-container {
  @apply flex flex-col h-full w-full min-w-0 ;
}

.page-title {
  @apply font-semibold text-lg md:text-xl;
}

.page-description {
  @apply font-light opacity-80 text-sm md:text-md;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Custom component layout
/* ----------------------------------------------------------------------------------------------------- */

.custom-component-layout {
  @apply flex h-full w-full relative bg-transparent;
}


/* IMAGE GALLERY */
lightbox {
  height: 90vh !important;

  gallery-core[imageSize=cover] gallery-item .g-image-item {
    object-fit: contain;
  }

  gallery-core[autoHeight=true][slidingDirection=horizontal] gallery-item {
    height: 100%;
  }

  .g-slider {
    transition: unset;
  }

  .g-slider-content {
    gap: 8px;
    margin: 8px 0;
  }
}

/* SLIDER PANEL */
.slider-panel-container {
  max-height: calc(100vh - 64px);
}

/* MEDIA SCREEN */
@media screen and (max-width: $breakpoint-md) {
  .title-class {
    font-size: 26px;
  }

  .table-template {
    &.mat-mdc-table {
      .mat-mdc-cell {
        font-size: 11px;
      }
    }

    &.mat-mdc-table .mat-mdc-header-cell {
      padding-bottom: 3px;
      padding-top: 3px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .g-overlay lightbox {
    max-width: unset;
    max-height: unset;
    position: fixed;
    inset: 0;
    height: 100% !important;
    width: 100%;
    border-radius: 0;
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  input,
  select,
  textarea {
    font-size: 14px !important;
  }

  input:focus,
  select:focus,
  textarea:focus {
    font-size: 14px !important;
  }
}
